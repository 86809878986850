import { DynamicContentType, LENDERS_TYPE } from 'applicationConstants';
import { ISubmission } from 'types/submissions';
import { defThunkRequest } from 'utils/defAsyncAction';
import { AllyDisclosure } from 'types/allyDisclosure';
import {
  getDynamicDataAction,
  getAllyApproveDisclosureAction,
  getAllyDisclosureAction,
  getAllySsnAction,
  getPowerPaySignatureAction,
  triggerAlphaeonVerificationAction,
  validateCardAction,
} from './actionTypes';
import {
  getDynamicContentRequest,
  getAllyApproveDisclosureRequest,
  getAllyDisclosuresRequest,
  getAllySsnRequest,
  getPowerPaySignature,
  IValidateCard,
  triggerAlphaeonVerificationRequest,
  validateCardRequest,
} from '../apiRequests';

export const validateCardThunk = defThunkRequest<{
  id: string;
  body: IValidateCard;
}>({
  actionTypes: validateCardAction,
  thunkSteps: [
    ({
      data: { id, body },
    }) => validateCardRequest(id, body),
  ],
});

export const getPowerPaySignatureThunk = defThunkRequest<{
  id: string;
}>({
  actionTypes: getPowerPaySignatureAction,
  thunkSteps: [
    ({
      data: { id },
    }) => getPowerPaySignature(id),
  ],
});

export const getAllyDisclosureThunk = defThunkRequest<{
  locationId: string;
}, Array<AllyDisclosure>>({
  actionTypes: getAllyDisclosureAction,
  thunkSteps: [
    ({
      data: { locationId },
    }) => getAllyDisclosuresRequest(locationId),
  ],
});
export const getAllyApproveDisclosureThunk = defThunkRequest<{
  applicationId: string;
}, {disclosures: Array<AllyDisclosure>}>({
  actionTypes: getAllyApproveDisclosureAction,
  thunkSteps: [
    ({
      data: { applicationId },
    }) => getAllyApproveDisclosureRequest(applicationId),
  ],
});

export const getDynamicDataThunk = defThunkRequest<{
  contentType: DynamicContentType[],
  lender: LENDERS_TYPE,
  submissionId: ISubmission['id'],
}>({
  actionTypes: getDynamicDataAction,
  thunkSteps: [
    ({
      data: {
        contentType,
        lender,
        submissionId,
      },
    }) => getDynamicContentRequest({ contentType, lender, submissionId }),
  ],
});

export const getAllySsnThunk = defThunkRequest<{
  locationId: string;
}, {'ssn': 'string'}>({
  actionTypes: getAllySsnAction,
  thunkSteps: [
    ({
      data: { locationId },
    }) => getAllySsnRequest(locationId),
  ],
});

export const triggerAlphaeonVerificationThunk = defThunkRequest<{
  applicationId: string;
}>({
  actionTypes: triggerAlphaeonVerificationAction,
  thunkSteps: [
    ({
      data,
    }) => triggerAlphaeonVerificationRequest(data.applicationId),
  ],
});
